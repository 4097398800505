/**
 * @class SequenceListener
 * @param {Object} configs
 * https://github.com/kegi/sequence-listener
 * @constructor
 */
export default class SequenceListener {
    constructor(configs)
    {
        this.initialize(configs)
        this.isSequenceListenerInitialized = false
    }

    /**
     * @method initialize
     * @param {Object} configs
     */
    initialize(configs)
    {
        if (this.isSequenceListenerInitialized) {
            return
        }

        this.isSequenceListenerInitialized = true

        this.configs = this.extendConfig({

            debug: false,
            maxKeyboardDelay: 75,
            minLength: 5,
            exactLength: null,
            allowedChars: '[a-zA-Z0-9]',
            ignoreInputs: true

        }, configs)

        this.validateConfigs()

        this.sequence = ''
        this.lastCharRecordedTime = null
        this.targetElement = null
        this.watcher = null

        this.listenKeyboard()
    }

    /**
     * @method validateConfigs
     */
    validateConfigs()
    {
        if (this.configs.exactLength === null && this.configs.minLength === null) {
            throw new Error('You need to specify "exactNbChars" or "minLength"')
        }

        if (this.configs.maxKeyboardDelay < 10 || this.configs.maxKeyboardDelay > 2000) {
            throw new Error('"maxKeyboardDelay" need to be between 10 and 2000')
        }
    }

    /**
     * @method extendConfig
     * @param {Object} defaultConfigs
     * @param {Object} configs
     * @returns {Object}
     */
    extendConfig(defaultConfigs, configs)
    {
        for (const key in configs) {
            if (configs.hasOwnProperty(key)) {
                defaultConfigs[key] = configs[key]
            }
        }

        return defaultConfigs
    }

    /**
     * @method listenKeyboard
     */
    listenKeyboard()
    {
        document.onkeyup = keyEvent => {
            this.keyUp(keyEvent)
        }
    }

    /**
     * @method keyUp
     * @param {Object} keyEvent
     */
    keyUp(keyEvent)
    {
        this.recordKey(keyEvent || window.event)
    }

    /**
     * @method recordKey
     * @param {Object} keyEvent
     */
    recordKey(keyEvent)
    {
        if (this.configs.ignoreInputs) {
            if (this.isInputTarget(keyEvent)) {
                this.clean(true)
                this.clearWatcher()

                return
            }
        }

        const charCode = keyEvent.charCode || keyEvent.keyCode
        const character = keyEvent.key || String.fromCharCode(charCode)

        if (character.length > 1 || !this.checkValidChar(character)) {
            return
        }

        this.clean(false)
        this.clearWatcher()

        this.lastCharRecordedTime = new Date().getTime()
        this.sequence += character

        if (this.sequence.length === 1) {
            this.targetElement = keyEvent.target
        }

        if (this.configs.debug) {
            console.log(this.sequence)
        }

        this.setWatcher()
    }

    /**
     * @method isInputTarget
     * @param {Object} event
     * @returns {boolean}
     */
    isInputTarget(event)
    {
        switch (event.target.tagName.toLowerCase()) {
            case 'input':
            case 'textarea':
                return true

            default:
                return false
        }
    }

    /**
     * @method checkValidChar
     * @param {string} input
     * @returns {boolean}
     */
    checkValidChar(input)
    {
        // if (this.configs.allowedChars) {
        //   return input.match(new RegExp(this.configs.allowedChars)) !== null
        // } else {
        return input
        // }

    }

    /**
     * @method clean
     * @param {boolean} force
     */
    clean(force)
    {
        if (force === undefined) {
            force = false
        }

        if (force || !this.checkDelay()) {
            if (this.configs.debug) {
                console.log('-- CLEARED --')
            }

            this.sequence = ''
        }
    }

    /**
     * @method checkDelay
     * @returns {boolean}
     */
    checkDelay()
    {
        return this.lastCharRecordedTime === null ||
            (new Date().getTime() - this.lastCharRecordedTime) <= this.configs.maxKeyboardDelay
    }

    /**
     * @method setWatcher
     */
    setWatcher()
    {
        this.watcher = setTimeout(() => {
            this.watcher = null
            this.checkCompleted()
        }, this.configs.maxKeyboardDelay)
    }

    /**
     * @method clearWatcher
     */
    clearWatcher()
    {
        if (this.watcher !== null) {
            clearTimeout(this.watcher)
            this.watcher = null
        }
    }

    /**
     * @method isRecordedInputValid
     * @param {string} input
     * @returns {boolean}
     */
    isRecordedInputValid(input)
    {
        console.log('valid?')
        return (this.configs.exactLength !== null && input.length === this.configs.exactLength) ||
            input.length >= this.configs.minLength
    }

    /**
     * @method checkCompleted
     */
    checkCompleted()
    {
        const sequence = this.sequence

        if (this.isRecordedInputValid(sequence)) {
            this.dispatchEvent(sequence)
        } else {
            this.clean(false)
        }
    }

    /**
     * @method newInputDetected
     * @param {string} sequence
     */
    dispatchEvent(sequence)
    {
        if (this.configs.debug) {
            console.log('=========================')
            console.log(`detected : ${sequence}`)
            console.log('=========================')
        }

        this.targetElement.dispatchEvent(new CustomEvent(
            'keyboardSequence',
            {
                detail: {
                    sequence
                }
            }
        ))
    }
}

// if (typeof define === 'function' && define.amd) {
//   define(() => {
//
//     if (typeof window.SequenceListener !== 'undefined') {
//       return window.SequenceListener
//     }
//
//     return window.SequenceListener = SequenceListener
//   })
// }
