/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import { createApp } from 'vue';
import SequenceListener from './SequenceListener';
import VueGtag from 'vue-gtag-next';

// Bootstrap may need to be updated or changed based on your project's dependencies and structure
require('./bootstrap');

const sequenceListener = new SequenceListener({
    debug: false,
    minLength: 8,
    ignoreInputs: true,
    allowedChars: false
});

sequenceListener.validateConfigs();

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = createApp({
    methods: {
        ga0(params = {}) {
            this.$gtag.event('scan', params)
        },
        ga1(params = {}) {
            this.$gtag.event('area_clienti', params)
        },
        ga2(params = {}) {
            this.$gtag.event('search', params)
        },
        ga3(params = {}) {
            this.$gtag.event('search-click', params)
        },
        ga4(params = {}) {
            this.$gtag.event('menu', params)
        },
        ga5(params = {}) {
            this.$gtag.event('cart', params)
        },
        ga6(params = {}) {
            this.$gtag.event('store-select', params)
        },
        ga7(params = {}) {
            this.$gtag.event('billing', params)
        },
        ga8(params = {}) {
            this.$gtag.event('thankyou', params)
        },
        ga10(params = {}) {
            this.$gtag.event('cancel-order', params)
        }
    }
});

app.use(VueGtag, {
    property: {
        id: "G-8CVKH4B214"
    }
});

app.mount('#app');
